<template>
    <div>
        <Toast position="top-center" group="tc"></Toast>
        <div style="display: none">
			<el-button id="trueBtn" v-print="'#printId'" type="info" size="mini" icon="el-icon-printer">打印</el-button>
				<div  id="printId" style="width: 100%;">
					<div class="print-box">
						<div class="flex-1 center" style="margin-top:15px"><img  id="barcodeId"></div>
						
						<div class="flex-1 center text1">{{barCodeObj.title}}</div>
						
						<div class="flex-1 center text1">{{barCodeObj.condition}}</div>

						<div class="flex-1 center text2">{{barCodeObj.tips}}</div>
					</div>
				</div>
		</div>
        <div class="card">
            <div class="mb-3" style="justify-content:space-between;display: flex;border-radius: 4px">
                <div>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="搜索FNSKU、SKU、ASIN" />
                    </span>
                    <Button type="button" icon="pi pi-refresh" label="刷新" class="ml-3" @click="getInventoryList()"/>
                </div>
                <div>
                    <Button type="button" icon="pi pi-filter-slash" label="清除" class="p-button-outlined" @click="clearFilter()"/>
                </div>
            </div>
            <DataTable stripedRows scrollable scrollHeight="calc(100vh - 16.5rem)" :value="inventory" :paginator="true" :rowsPerPageOptions="[10,20,50]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="SKU总数：{totalRecords}" class="p-datatable-customers" :rows="20"
                dataKey="fnsku" v-model:filters="filters" :globalFilterFields="['asin','sku','fnsku']" filterDisplay="menu" :loading="loading" responsiveLayout="scroll">
                <template #empty>
                    {{isempty}}
                </template>
                <Column header="商品信息" style="min-width:15rem">
                    <template #body="{data}">
                        <itemInfo :item="{imgUrl:data.SmallImage,asin:'1',fnsku:'1'}">
                            <template #asin>
                                <a target="_blank" :href="'https://www.amazon.com/dp/'+data.asin">{{data.asin}}</a>
                                <Button icon="pi pi-clone" class="p-button-rounded p-button-text p-button-sm" style="border:none;" title="复制ASIN" @click="copy(data.asin)" />
                            </template>
                            <template #fnsku>
                                {{data.fnsku}}
                                <Button icon="pi pi-print" class="p-button-rounded p-button-text p-button-sm" style="border:none;" title="打印条码" @click="printClick(data.fnsku)" />
                            </template>
                        </itemInfo>
                    </template>
                </Column>

                <Column header="店铺" field="Store" :filterMenuStyle="{'height':'5.3rem'}" :showClearButton="false" :showApplyButton="false" :showFilterMatchModes="false" style="min-width:3rem">
                    <template #filter="{filterModel,filterCallback}">
                        <Dropdown v-model="filterModel.value" @change="filterCallback()" :showClear="true" :options="stores" placeholder="全部" optionLabel="storeName" optionValue="storeName" class="p-column-filter" />
                    </template>
                </Column>

                <Column header="SKU" style="min-width:14rem">
                    <template #body="{data}">
                        <div class="product-item">
                            <div class="product-list-detail">
                                <div class="product-sku mb-1">{{data.sku}}</div>
                                <div class="product-sku">{{data.productNameZh}}</div>
                            </div>
                        </div>
                    </template>
                </Column>
                <Column header="可售"  field="afn_fulfillable_quantity" style="min-width:1rem" />
                <Column header="在途"  field="afn_inbound_shipped_quantity" style="min-width:1rem" />
                <Column header="接收中" field="afn_inbound_receiving_quantity" style="min-width:1rem" />
                <Column header="预留中"  field="afn_reserved_quantity" style="min-width:2rem" />
                <Column header="不可售"  field="afn_unsellable_quantity" style="min-width:1rem" />
                <Column header="处理中"  field="afn_inbound_working_quantity" style="min-width:1rem" />
                <Column header="昨日销量" field="Yestoday" style="min-width:1rem" />
                <Column header="3日销量" field="ThreeDay" style="min-width:1rem" />
                <Column header="7日销量" field="SevenDay" style="min-width:1rem" />
                <Column header="14日销量" field="ForteenDay" style="min-width:1rem" />
                <Column header="30日销量" field="ThirtyDay" style="min-width:1rem" />
                <Column header="60日销量" field="SixtyDay" style="min-width:1rem" />
                <Column header="90日销量" field="NinetyDay" style="min-width:1rem" />
            </DataTable>
        </div>
    </div>
</template>

<script>
import Service from '../../service/Service';
import {FilterMatchMode} from 'primevue/api';
import JsBarcode from 'jsbarcode';
import useClipboard from 'vue-clipboard3';
import itemInfo from '../common/itemInfo.vue';

export default {
    data() {
        return {
            inventory: [],
            filters: {},
            stores: [
            ],
            loading: true,
            isempty:'',
            barCodeObj:{
				code:'X002IAKQ05',
				title:'BODISEINT Modern Soft P....6" Dx7.9 H), Champagne)',
				condition:'New',
				tips:'Made In China'
			}
        }
    },
    created() {
        this.service = new Service();
        this.initFilters();
    },
    mounted() {
        this.stores=JSON.parse(localStorage.getItem('stores'));
        this.getInventoryList();
    },
    watch:{
    },
    methods: {
        printClick(fnsku) {
			var items=this.inventory.filter(i => i.fnsku==fnsku);
            if(items){
                if(items[0].subTitle){
                    this.barCodeObj.code=items[0].fnsku;
                    this.barCodeObj.title=items[0].subTitle;
                    this.printLabel();
                }
                else{
                    alert('请输入产品名称！');
                }
            }
		},
        printLabel(){
            let options = {
					format: "CODE128",
					height: 140,
					width:5,
					fontOptions:'bold',
					displayValue: true,
					text: this.barCodeObj.code,
					font: "Arial",
					textAlign: "center",
					textPosition: "bottom",
					textMargin: 2,
					fontSize: 40
				}
				this.$nextTick(() => {
					JsBarcode("#barcodeId", this.barCodeObj.code, options);
				});
				setTimeout(() => {
					document.getElementById("trueBtn").click();
				}, 1000);
        },
        getInventoryList(){
            this.isempty='正在加载...';
            this.inventory = null; 
            this.loading = true;
            var url='inventory/getinventorylist.php';
            this.service.sendHttp(url,'POST').then(res=>res.json()).then(data => {
                this.loading = false;
                this.isempty='无数据！';
                if(data.result=='success'){
                    this.inventory = data.inventory; 
                }
            });
        },
        clearFilter() {
            this.initFilters();
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'Store': {value: null, matchMode: FilterMatchMode.EQUALS}
            }
        },
        copy(text){
            const { toClipboard } = useClipboard();
            toClipboard(text).then(
                ()=>{this.showSuccess('复制成功！')},
                ()=>{this.showError('复制失败！')}
            );
        },
        showSuccess(mes='核对成功!') {
            this.$toast.add({severity: 'success', summary: mes,  group: 'tc',life:1000});
        },
        showError(mes='核对失败!') {
            this.$toast.add({severity: 'error', summary: mes,  group: 'tc',life:1000});
        }
    },
    components: {
        'itemInfo': itemInfo
    }
}
</script>
<style media="print">
@page {
   size: auto;
	margin: 0;
	padding: 0;
}
#printId {
  margin: 0;
  padding: 0;
  height: 98%;
}
</style>
<style lang="scss" scoped>
/* 打印样式开始 */
.center{
		text-align: center;
	}
	.flex-1{
		flex: 1;
		margin-top: 5px;
        
	}
	.text1{
		font-size: 49px;
		font-family: Arial, Helvetica, sans-serif;
        padding:0 20px;
	}
	.text2{
		font-size: 70px;
		font-family: 'Microsoft YaHei';
        // font-weight:bold;
	}
	.print-box {
		padding-top: 30px;
		width: 960px;
		height: 576px;
		border-radius: 10px;
		background-color: #fff;
	}
//打印样式结束
</style>